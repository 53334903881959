<template>
  <div class="w-full max-h-screen bg-background flex flex-col relative">
    <page-header title="Staff Management" backTo="internal">
      <is-authorized :permissions="['manage:staff', 'create:staff']">
        <router-link
          :to="{ name: 'staff-create' }"
          class="flex items-center bg-green-200 border hover:bg-green-300 border-green-400 hover:border-green-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        >
          <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
          >
            <path d="M12 5v14M5 12h14" />
          </svg>
          <span>Create Staff</span>
        </router-link>
      </is-authorized>
    </page-header>

    <div class="p-6 bg-background flex-1 overflow-y-scroll">
      <div class="w-full bg-white p-5 rounded shadow-md relative">
        <div class="w-full flex items-center justify-between">
          <div class="flex space-x-2">
            <button
              @click="selectTab('all')"
              class="
                px-4
                py-2
                rounded
                transition-colors
                duration-300
                flex
                items-center
                font-semibold
              "
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'all',
                'hover:bg-gray-200': currentTab != 'all',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <path d="M8 6h13M8 12h13M8 18h13M3 6h.01M3 12h.01M3 18h.01" />
              </svg>
              <span>Manage Staff</span>
            </button>

            <button
              @click="currentTab = 'search'"
              class="
                px-4
                py-2
                rounded
                transition-colors
                duration-300
                flex
                items-center
                font-semibold
              "
              :class="{
                'bg-orange-300 text-orange-900': currentTab == 'search',
                'hover:bg-gray-200': currentTab != 'search',
              }"
            >
              <svg
                class="stroke-current h-5 w-5 mr-2"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
              <span>Search</span>
            </button>
          </div>
          <div class="flex flex-1 justify-end">
            <input
              type="text"
              class="
                ml-10
                w-full
                lg:w-1/2
                rounded-l
                bge-input bge-input-spacing
              "
              placeholder="Search staff by name, department, job title, city, postcode and mobile"
              v-model="searchTerm"
              @keydown.enter="searchStaffs"
            />
            <button
              @click="searchStaffs"
              class="
                rounded-r
                bg-blue-200
                hover:bg-blue-300
                border border-blue-400
                hover:border-blue-500
                p-2
                hover:shadow
                active:shadow-inner
                transition-colors
                duration-300
                ease-in-out
              "
            >
              <svg
                class="stroke-current h-5 w-5"
                fill="none"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                viewBox="0 0 24 24"
              >
                <circle cx="11" cy="11" r="8" />
                <path d="M21 21l-4.35-4.35" />
              </svg>
            </button>
          </div>
        </div>
        <div v-if="viewState == 'Idle'">
          <div v-if="currentTab == 'all'">
            <vue-good-table
              class="mt-5"
              styleClass="vgt-table striped"
              mode="remote"
              :rows="staffs"
              :columns="columns"
              :totalRows="totalRecords"
              @on-row-click="onStaffRowSelected"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :pagination-options="{
                enabled: true,
                perPage: pagingParams.limit,
                perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                dropdownAllowAll: false,
              }"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'first_name'">
                  {{ props.row.first_name }} {{ props.row.last_name }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
          <div v-if="currentTab == 'search'">
            <div v-show="searchState == 'Idle'">
              <h4 class="mt-5" v-if="searchTermWas && totalSearchResults > 0">
                {{ totalSearchResults }} search results for '{{
                  searchTermWas
                }}'
              </h4>
              <vue-good-table
                class="mt-5"
                mode="remote"
                styleClass="vgt-table striped"
                :rows="searchResults"
                :columns="columns"
                :totalRows="totalSearchResults"
                :row-style-class="markRowAsSelected"
                @on-row-click="onStaffRowSelected"
                @on-page-change="onPageChange"
                @on-sort-change="onSortChange"
                @on-column-filter="onColumnFilter"
                @on-per-page-change="onPerPageChange"
                :pagination-options="{
                  enabled: true,
                  perPage: searchPagingParams.limit,
                  perPageDropdown: [1, 2, 3, 10, 20, 50, 100],
                  dropdownAllowAll: false,
                }"
              >
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'first_name'">
                    {{ props.row.first_name }} {{ props.row.last_name }}
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
              <h5
                class="text-center py-3"
                v-if="searchTermWas && searchResults.length == 0"
              >
                No results for {{ searchTermWas }}
              </h5>
            </div>
            <div
              v-if="searchState == 'Searching'"
              class="flex items-center justify-center py-20"
            >
              <Spinner :color="'text-black'" :size="10" />
            </div>
            <div v-if="searchState == 'Error'">
              <div class="mt-5 p-6 rounded bg-red-300">
                Something went wrong performing your search, please try again.
                If the problem persists, please contact your support team.
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="viewState == 'Loading'"
          class="flex items-center justify-center py-20"
        >
          <spinner :color="'text-black'" :size="10" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
import Panel from "@/components/Panel.vue";
const IsAuthorized = () => import("@/components/Auth/IsAuthorized.vue");

export default {
  components: {
    PageHeader,
    Spinner,
    Panel,
    IsAuthorized,
  },
  data() {
    return {
      viewState: "Idle",
      searchState: "Idle",
      currentTab: "all",
      searchTerm: "",
      searchTermWas: "",
      searchResults: [],
      totalSearchResults: 0,
      error: null,
      totalRecords: 0,
      staffToEdit: {},
      pagingParams: {
        page: 1,
        limit: 50,
        orderBy: "first_name",
      },
      searchPagingParams: {
        page: 1,
        limit: 50,
        orderBy: "first_name",
      },
      staffs: [],
      columns: [
        {
          label: "Name",
          field: "first_name",
          sortable: true,
        },
        {
          label: "Department",
          field: this.formatDepartmentFn,
          sortable: false,
        },
        {
          label: "Job Title",
          field: "job_title",
          sortable: true,
        },
        {
          label: "Mobile",
          field: "mobile",
          sortable: false,
        },
        {
          label: "Email",
          field: "email",
          sortable: false,
        },
      ],

      showingCreateStaffPanel: false,
    };
  },
  async mounted() {
    try {
      this.viewState = "Loading";
      await this.loadStaffs();
      this.searchTerm = this.$store.state.staffSearchTerm;
      await this.searchStaffs();
      this.viewState = "Idle";
    } catch (error) {
      console.error(error);
      this.viewState = "Error";
      this.error = error;
    }
  },
  methods: {
    fullName(rowObj) {
      return rowObj.first_name + " " + rowObj.last_name;
    },
    formatDepartmentFn(rowObj) {
      return Array.isArray(rowObj.department)
        ? rowObj.department.join(", ")
        : rowObj.department;
    },
    selectTab: function(tabName) {
      this.$nextTick(() => {
        this.currentTab = tabName;
      });
      this.$store.dispatch("setStaffSearchTerm", "");
    },

    async searchStaffs() {
      if (!this.searchTerm || this.searchTerm.length == 0) return;

      this.$store.dispatch("setStaffSearchTerm", this.searchTerm);

      try {
        this.hasSearchResults = true;
        this.searchState = "Searching";

        this.$nextTick(() => {
          this.currentTab = "search";
        });

        let results = await this.StaffService.searchStaff(
          this.searchTerm,
          this.searchPagingParams
        );

        this.searchResults = results.data;
        this.totalSearchResults = results.count;
        this.searchTermWas = this.searchTerm;
        this.searchState = "Idle";
      } catch (err) {
        console.error(err);
        this.searchState = "Error";
      }
    },

    async loadStaffs() {
      let staffsResult = await this.StaffService.getStaffs(this.pagingParams);

      this.staffs = staffsResult.data;
      this.totalRecords = staffsResult.count;
    },

    onStaffRowSelected(selectedRow) {
      console.log(selectedRow);
      this.$store.dispatch("setLastSelectedStaff", selectedRow.row.staff_id);
      this.$router.push({
        name: "staff-edit",
        params: { staffId: selectedRow.row.staff_id },
      });
    },

    async onPageChange(params) {
      this.updatePagingParams({ page: params.currentPage });
      if (this.currentTab == "search") {
        await this.searchStaffs();
      } else {
        await this.loadStaffs();
      }
    },

    async onPerPageChange(params) {
      this.updatePagingParams({ limit: params.currentPerPage, page: 1 });
      if (this.currentTab == "search") {
        await this.searchStaffs();
      } else {
        await this.loadStaffs();
      }
    },

    async onSortChange(params) {
      this.updatePagingParams({
        order: params[0].type,
        orderBy: params[0].field,
        page: 1,
      });
      if (this.currentTab == "search") {
        await this.searchStaffs();
      } else {
        await this.loadStaffs();
      }
    },

    markRowAsSelected: function(row) {
      return this.$store.state.lastSelectedStaff == row.staff_id
        ? "bg-green-300"
        : "";
    },

    updatePagingParams(newProps) {
      if (this.currentTab == "search") {
        this.searchPagingParams = Object.assign(
          {},
          this.searchPagingParams,
          newProps
        );
      } else {
        this.pagingParams = Object.assign({}, this.pagingParams, newProps);
      }
    },

    onColumnFilter(params) {
      console.log("onColumnFilter", params);
    },

    saveStaff: async function(staff) {
      // Ensure Staff has mandatory fields filled out
      if (!staff.last_name) {
        let msg = "Please add a last name";

        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "warning",
                  title: "Error",
                  close: onClose,
                },
              },
              msg
            );
          },
          {
            position: "top-right",
          }
        );
      }

      this.showingCreateStaffPanel = false;

      try {
        if (!staff.staff_id) {
          let createResult = await this.StaffService.createStaff(staff);
          staff.staff_id = createResult.data.staff_id;
          this.staffs.push(staff);
        } else {
          await this.StaffService.updateStaff(staff);
          let idx = this.$_.findIndex(
            this.staffs,
            (c) => c.staff_id == staff.staff_id
          );
          this.staffs.splice(idx, 1, staff);
        }

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Details Saved",
                  close: onClose,
                },
              },
              "Staff details saved"
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (err) {
        console.error(err);

        let msg = !staff.staff_id
          ? "There was a problem creating the new Staff"
          : "There was a problem updating the Staff";

        this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There was a problem updating the Staff"
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
    editStaff: function(Staff) {
      console.log(Staff);
      this.staffToEdit = staff;
      this.showingCreateStaffPanel = true;
    },
    closePanel: function() {
      this.showingCreateStaffPanel = false;
      this.staffToEdit = {};
    },
  },
};
</script>
